import { useEffect, useRef } from "react";
import "./App.css";
import { motion } from "framer-motion";
import firstvideobeforeandafter from "./Videos/BHANU PATHAK PORTFOLIOO.mp4";
import feedbackPayal from "./Videos/Payal Feedback.mp4";
// import { FaInstagram } from "react-icons/fa";
import {
  FaSquareXTwitter,
  FaLinkedin,
  FaSquareInstagram,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa6";

import shortv1 from "./Videos/Data S.mp4";
import shortv2 from "./Videos/res.mp4";
import shortv3 from "./Videos/WhatsApp Video 2024-08-13 at 15.36.50_6fc397bc.mp4";
import shortv4 from "./Videos/popt11.mp4";
import shortv5 from "./Videos/Port22.mp4";
import shortv6 from "./Videos/Gaurav33.mp4";

import gaurav from "./Videos/gaurav.jpeg";
import payal from "./Videos/payal.jpeg";
import bhanu from "./Videos/bhanu.jpeg";
import aadi from "./Videos/aadi.jpeg";
import ezedit from "./Videos/ezedit.jpeg";
import divyanshu from "./Videos/divyanshu.jpeg";
import ruchi from "./Videos/ruchi.jpeg";
import bihariMotions from './Media/bihari motion.jpg'

import software1 from "./Videos/Adobe_After_Effects_CC_icon.svg.png";
import software2 from "./Videos/Adobe_Illustrator_CC_icon.svg.png";
import software3 from "./Videos/Adobe_Photoshop_CC_icon.svg.png";
import software4 from "./Videos/Notion-logo.svg.png";
import software5 from "./Videos/canva.png";
import software6 from "./Videos/Adobe_Premiere_Pro_CC_icon.svg.png";

function App() {

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.log("Autoplay blocked:", error);
      });
    }
  }, []);
  return (
    <div id="wrapper">
      <div id="main">
        <div className="inner">
          <div
            id="container01"
            className="style1 container default full screen"
          >
            <div className="wrapper">
              <div className="inner" data-onvisible-trigger={1}>
                <ul id="buttons04" className="style2 buttons">
                  <li>
                    {/* <a href="#" class="button n01 rainbow-btn">For content creators</a> */}
                    <a href="/" className="button n01 ">
                      For content creators
                    </a>
                    {/* <a href="https://webtoolastra.com" class="rainbow-btn"><span>Click Me</span></a> */}
                  </li>
                </ul>

                <motion.h2
                  initial={{ opacity: 0 }} // Initial state (hidden)
                  animate={{ opacity: 1 }} // Final state (visible)
                  transition={{ duration: 3 }} // Animation duration
                  id="text02"
                  className="style9"
                >
                  <span className="p">
                    Hey, I create{" "}
                    <u>
                      <mark>
                        engaging
                        <br />
                        edits
                      </mark>
                    </u>{" "}
                    that increase views
                  </span>
                </motion.h2>

                <motion.p
                  initial={{ x: "-100vw" }} // Start off-screen (left)
                  animate={{ x: 0 }} // Slide to original position
                  transition={{ type: "spring", stiffness: 50 }} // Smooth spring animation
                  id="text01"
                  className="style2"
                >
                  <span className="p">
                    I have edited over 500+ videos for multiple creators on
                    instagram,
                  </span>
                  <span className="p">youtube and other media platforms</span>
                </motion.p>
                <ul id="buttons06" className="style3 buttons">
                  <li>
                    <a href="#scrollpoint01" className="button n01">
                      {/* <svg aria-labelledby="icon-title">
                        <title id="icon-title">Chevron Down</title>
                        <use xlinkHref="#icon-539e8a7dd31ef103fa8a43b109673d75" />
                      </svg> */}
                      <span className="label">Here's more of my work</span>
                    </a>
                  </li>
                </ul>

                <motion.div
                  initial={{ x: -100, opacity: 0, scale: 0.8 }} // Start off-screen, invisible, and small
                  animate={{ x: 0, opacity: 1, scale: 1 }} // Move to center, fade-in, and scale up
                  transition={{ duration: 1, ease: "easeInOut" }}
                  id="video01"
                  className="video"
                >
                  <div className="frame">
                    <video
                     ref={videoRef}
                      src={shortv1}
                      poster="assets/videos/video03.mp4.jpg?v=985bb2e4"
                      preload="auto"
                      playsInline
                      webkit-playsinline
                      muted
                      autoPlay
                      loop
                      
                      className="first-information-video-first-video"
                    />

                   
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
          <p
            id="scrollpoint01"
            data-scroll-id="scrollpoint01"
            data-scroll-behavior="default"
            data-scroll-offset={0}
            data-scroll-speed={3}
            className="style4"
          >
            Shorts
          </p>

          <motion.div
            initial={{ opacity: 0, y: 50 }} // Start invisible and slightly below
            whileInView={{ opacity: 1, y: 0 }} // Fade in and slide up when in view
            transition={{ duration: 1 }}
            id="container03"
            className="style2 container columns"
          >
            <div className="wrapper">
              <div className="inner" data-onvisible-trigger={1}>
                <div>
                  <div id="video03" className="style1 video">
                    <video
                      src={firstvideobeforeandafter}
                      poster="assets/videos/video03.mp4.jpg?v=985bb2e4"
                      preload="auto"
                      playsInline
                      webkit-playsinline
                      muted
                      autoPlay
                      loop
                      
                    />
                  </div>
                </div>
                <div>
                  <div id="video06" className="style1 video">
                    <video
                     ref={videoRef}
                      src={shortv2}
                      poster="assets/videos/video06.mp4.jpg?v=985bb2e4"
                      preload="auto"
                      playsInline
                      webkit-playsinline
                      muted
                      autoPlay
                      loop
                      
                    />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
          <div id="container02" className="style2 container columns">
            <div className="wrapper">
              <div className="inner" data-onvisible-trigger={1}>
                <div>
                  <div id="video04" className="style1 video">
                    <video
                     ref={videoRef}
                      src={shortv3}
                      poster="assets/videos/video04.mp4.jpg?v=985bb2e4"
                      preload="auto"
                      playsInline
                      webkit-playsinline
                      muted
                      autoPlay
                      loop
                      
                    />
                  </div>
                </div>
                <div>
                  <div id="video04" className="style1 video">
                    <video
                     ref={videoRef}
                      src={shortv4}
                      poster="assets/videos/video04.mp4.jpg?v=985bb2e4"
                      preload="auto"
                      playsInline
                      webkit-playsinline
                      muted
                      autoPlay
                      loop
                      
                    />
                  </div>
                </div>
                {/* <div>
                  <div id="video05" className="style1 video youtube-container-rahul">
                  <iframe src="https://www.youtube.com/embed/TUBE4n8hP3o" frameborder="0" allowfullscreen
                     
                      poster="assets/videos/video05.mp4.jpg?v=985bb2e4"
                      preload="auto"
                      title='rahulMotion'
                      playsInline
                      webkit-playsinline
                      muted
                      autoPlay
                      loop
                      
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div id="container02" className="style2 container columns">
            <div className="wrapper">
              <div className="inner" data-onvisible-trigger={1}>
                <div>
                  <div id="video04" className="style1 video">
                    <video
                     ref={videoRef}
                      src={shortv5}
                      poster="assets/videos/video04.mp4.jpg?v=985bb2e4"
                      preload="auto"
                      playsInline
                      webkit-playsinline
                      muted
                      autoPlay
                      loop
                      
                    />
                  </div>
                </div>
                <div>
                  <div id="video04" className="style1 video">
                    <video
                     ref={videoRef}
                      src={shortv6}
                      poster="assets/videos/video04.mp4.jpg?v=985bb2e4"
                      preload="auto"
                      playsInline
                      webkit-playsinline
                      muted
                      autoPlay
                      loop
                      
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p id="text18" className="style4">
            long form
          </p>
          <div id="container05" className="style2 container columns">
            <div className="wrapper">
              <div className="inner" data-onvisible-trigger={1}>
                <div>
                  <div id="video02" className="style1 video">
                    <div className="frame">
                      <iframe
                        src="https://www.youtube.com/embed/2HbozGsn4Oc?si=rEiLtd0QQi4bT5eX"
                        allowFullScreen={1}
                        title="Rahul motion"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div id="video09" className="style1 video">
                    <div className="frame">
                      <iframe
                        src="https://www.youtube.com/embed/ZhiT7mK0AfY?si=07kOFHQo3G9F15ut"
                        allowFullScreen={1}
                        title="Rahul motion"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div id="video07" className="style1 video">
            <div className="frame">
              <iframe
                src="https://www.youtube-nocookie.com/embed/6kvmGDpEbN0?autoplay=1&mute=1&rel=0&loop=1&=1&cc_load_policy=0&start=30&playlist=6kvmGDpEbN0"
                allowFullScreen={1}
              />
            </div>
          </div> */}
          <ul id="buttons01" className="style4 buttons">
            <li>
              <a
                href="https://www.instagram.com/_this_is_rahul_45/"
                className="button n01"
              >
                <svg aria-labelledby="icon-title">
                  <title id="icon-title">Arrow Right (Light)</title>
                  <use xlinkHref="#icon-67ad41ecc66a1b1cd00d0d3b20c00017" />
                </svg>
                <span className="label">Message me</span>
              </a>
            </li>
          </ul>
          <div
            id="container11"
            className="style7 container columns full screen"
          >
            <div className="wrapper">
              <div className="inner" data-onvisible-trigger={1}>
                <div>
                  <h2 id="text07" className="style3">
                    50M<mark>+</mark>
                  </h2>
                  <p id="text10" className="style6">
                    Views generated
                  </p>
                </div>
                <div>
                  <h2 id="text05" className="style3">
                    20<mark>+</mark>
                  </h2>
                  <p id="text08" className="style6">
                    Clients
                  </p>
                </div>
                <div>
                  <h2 id="text06" className="style3">
                    500<mark>+</mark>
                  </h2>
                  <p id="text09" className="style6">
                    videos edited
                  </p>
                </div>
              </div>
            </div>
          </div>
          <h2 id="text11" className="style3">
            Clients I have worked with
          </h2>
          <div
            id="container09"
            className="style6 container columns full screen"
          >
            <div className="wrapper">

              {/* clint first row */}
              <div className="inner" data-onvisible-trigger={1}>
                <a href="https://www.instagram.com/bhanu_pathak/?hl=en">
                  <div
                    id="image02"
                    className="style11 image"
                    data-position="center"
                  >
                    <span className="frame">
                      <img src={bhanu} alt="" />
                    </span>
                  </div>
                  <p id="text17" className="style11">
                    Bhanu Pathak - <u>800K followers</u>
                  </p>
                </a>

                <a href="https://www.instagram.com/payalineurope/?hl=en">
                  <div
                    id="image04"
                    className="style12 image"
                    data-position="center"
                  >
                    <span className="frame">
                      <img src={payal} alt="" />
                    </span>
                  </div>
                  <p id="text15" className="style10">
                    Payal & Gaurav - <u>376K followers</u>
                  </p>
                </a>
              </div>

              <br/>
{/* Clint second Row */}
              <div className="inner" data-onvisible-trigger={1}>
              

                <a href="https://www.instagram.com/thegauravghai/?igsh=MWU5bWtrMDk1cWFoZw%3D%3D#">
                  <div
                    id="image04"
                    className="style12 image"
                    data-position="center"
                  >
                    <span className="frame">
                      <img src={gaurav} alt="" />
                    </span>
                  </div>
                  <p id="text15" className="style10">
                  Gaurav Ghai - <u>499K followers</u>
                  </p>
                </a>

                <a href="https://www.instagram.com/divyanshu.jain?igsh=OTYxaXV6dnJxYnFr">
                  <div
                    id="image02"
                    className="style11 image"
                    data-position="center"
                  >
                    <span className="frame">
                      <img src={divyanshu} alt="" />
                    </span>
                  </div>
                  <p id="text17" className="style11">
                  Divyanshu Jain - <u>463K followers</u>
                  </p>
                </a>
              </div>

<br/>
{/* clint 3rd Row */}
<div className="inner" data-onvisible-trigger={1}>
                <a href="https://www.instagram.com/biharimotions?igsh=OXUxMGltbHZzZzds">
                  <div
                    id="image02"
                    className="style11 image"
                    data-position="center"
                  >
                    <span className="frame">
                      <img src={bihariMotions} alt="" />
                    </span>
                  </div>
                  <p id="text17" className="style11">
                  biharimotions - <u>35.5K followers</u>
                  </p>
                </a>

                <a href="https://www.instagram.com/adi__arora?igsh=MWRkbzJydHpqN3Z0cA%3D%3D">
                  <div
                    id="image04"
                    className="style12 image"
                    data-position="center"
                  >
                    <span className="frame">
                      <img src={aadi} alt="" />
                    </span>
                  </div>
                  <p id="text15" className="style10">
                  Aditya Arora - <u>159K followers</u>
                  </p>
                </a>
              </div>

              <br/>
{/* clint 4th Row */}
<div className="inner" data-onvisible-trigger={1} style={{textAlign:'center', display:'flex', justifyContent:'center'}}>
                <a href="https://www.instagram.com/eatfitrepeat.efr?igsh=MXV4cWp5YmVkNXNzYg%3D%3D">
                  <div
                    id="image02"
                    className="style11 image"
                    data-position="center"
                  >
                    <span className="frame">
                      <img src={ruchi} alt="" />
                    </span>
                  </div>
                  <p id="text17" className="style11">
                  Ruchi Sharmma - <u>62.8K followers</u>
                  </p>
                </a>

               
              </div>


            </div>
          </div>

          <h2 id="text21" className="style3">
            Testimonial
          </h2>
          <div
            id="container10"
            className="style6 container default full screen"
          >
            <div className="wrapper">
              <div className="inner" data-onvisible-trigger={1}>
                <div id="video08" className="style2 video">
                  <div className="frame">
                    <iframe src={feedbackPayal} allowFullScreen={1} title="testemony"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p id="text20" className="style13">
            ⭐⭐⭐⭐⭐
          </p>
          <p id="text12" className="style8">
            <u>- Client</u>
          </p>
          <h2 id="text03" className="style7">
            <span style={{ color: "#E2E2B6" }}>Rahul</span> is the guy..."
          </h2>
          <ul id="buttons03" className="style6 buttons">
            <li>
              <a href="https://wa.me/919334609201" className="button n01">
                <FaWhatsapp />
                <span className="label">Message me</span>
              </a>
            </li>
          </ul>
          <br />
          <br />
          <h2 className="software-im-using-for-heading style3" id="text21">
            Tools I Work With
          </h2>
          <div className="software-im-using-for-container">
            <div className="each-software-image-ontainer">
              <img src={software1} alt="software" />
            </div>
            <div className="each-software-image-ontainer">
              <img src={software2} alt="software" />
            </div>
            <div className="each-software-image-ontainer">
              <img src={software3} alt="software" />
            </div>
            <div className="each-software-image-ontainer">
              <img src={software4} alt="software" />
            </div>
            <div className="each-software-image-ontainer">
              <img src={software5} alt="software" />
            </div>
            <div className="each-software-image-ontainer">
              <img src={software6} alt="software" />
            </div>
          </div>
          <div
            id="container06"
            className="style6 container columns full screen"
          >
            <div className="wrapper">
              <div className="inner" data-onvisible-trigger={1}>
                <div>
                  <ul id="icons01" className="style1 icons">
                    <li>
                      <a
                        className="n01"
                        href="https://x.com/rahul_motion?s=11"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaSquareXTwitter />
                        <span className="label">X</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="n02"
                        href="https://www.instagram.com/rahul_motion?igsh=MTJuZjJ0aHlwd2diOA=="
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaSquareInstagram />
                        <span className="label">Instagram</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="n02"
                        href="https://www.linkedin.com/in/rahul-kumar-03aaa7242?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaLinkedin />
                        <span className="label">Linkedin</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="n02"
                        href="https://www.youtube.com/shorts/TUBE4n8hP3o"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaYoutube />
                        <span className="label">Linkedin</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p id="text30" className="style2">
                    © Rahul. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="developer-details-r-m">
        <p>
          Website developed by Brajesh Thakur. Visit{" "}
          <a href="https://brajesh.tech/" target="_blank" rel="noreferrer">
            brajesh.tech
          </a>{" "}
          for more projects.
        </p>
      </div>
    </div>
  );
}

export default App;
